import React from "react"
import PropTypes from "prop-types"

import { graphql } from "gatsby"

import { ContentImpression } from "@src/lib/impression"
import classNames from "classnames"

import ApplyCTA from "@src/components/ApplyCTA"
import FaqSection from "@src/components/FaqSection"
import StructuredContentRenderer from "@src/components/StructuredContentRenderer"
import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import AuthorsSection from "@src/components/AuthorsSection"
import TrustbuildingCTA from "@src/components/TrustbuildingCTA"
import SMBCompareLoansEntryPoint from "@src/components/SMBCompareLoansEntryPoint"

import Box from "@src/components/Box"
import DateFormat from "@src/components/DateFormat"
import Type from "@nerdwallet/react-typography"

import { assignments } from "@src/lib/variations"
import { LocationVariantProvider } from "@src/hooks/useLocationVariant"
import { ApplyUrlProvider } from "@src/hooks/useApplyUrl"
import RecapSection from "@src/components/roundup/RecapSection"
import ProductsSection from "@src/components/roundup/ProductSection"
import LendersTableSection from "@src/components/roundup/LendersTableSection"
import ExpandableContent from "@src/components/ExpandableContent"
import Methodology from "@src/components/roundup/Methodology"
import { useAnalyticsPageTrack } from "@src/hooks/useAnalyticsPageTrack"
import { getCanonicalLink } from "@src/lib/helpers"
import useFeatureFlag from "@src/hooks/useFeatureFlag"

import styles from "./roundup.module.less"

const isValidSection = section => !!section.marketplaceEntity

const ProductTable = ({
  validSections,
  productDescription,
  isContentTarget,
}) => {
  const replaceReviewLink = useFeatureFlag(
    "replace-review-link-on-summary-table"
  )

  const lendersSection = (
    <div
      data-cy="lenders-table"
      className={styles.lendersTableWrapper}
      key="lenders-section"
    >
      <LendersTableSection
        sections={validSections}
        sort={validSections.length >= 4}
        productDescription={productDescription}
      />
    </div>
  )

  const productSection = (
    <Box className={styles.defaultMargin} key="product-section">
      <ProductsSection
        sections={validSections}
        isContentTarget={isContentTarget}
      />
    </Box>
  )

  const cta = <ApplyCTA key="apply-cta" />

  if (replaceReviewLink) {
    return [productSection, lendersSection, cta]
  }

  return [lendersSection, productSection, cta]
}

const ContentCTA = () => {
  if (useFeatureFlag("replace-cta-component-on-loan-for-minorities")) {
    return <SMBCompareLoansEntryPoint withVerticalMargin />
  }
  if (process.env.GATSBY_TRUSTBUILDING_CTA_ON_ROUNDUPS_PAGES === "true") {
    return <TrustbuildingCTA headerSize="large" />
  }
  return <ApplyCTA />
}

ProductTable.propTypes = {
  validSections: PropTypes.array.isRequired,
  productDescription: PropTypes.string.isRequired,
}

const RoundUp = ({ data, location }) => {
  const { smbRoundup: roundup } = data

  useAnalyticsPageTrack(roundup.contentId)
  const validSections = roundup.roundupSections.filter(isValidSection)
  const hideRecapSection = useFeatureFlag("hide-recap-section")

  const header = (
    <Header attributionTopic={roundup.attributionTopic} hideBanner />
  )

  const headerContent = (
    <HeaderContent
      title={roundup.title}
      date={roundup.displayDate}
      authors={roundup.authors}
      synopsis={
        <StructuredContentRenderer>
          {roundup.synopsis}
        </StructuredContentRenderer>
      }
      breadcrumb={roundup.breadcrumb}
      featuredImage={roundup.featuredMedia}
      headerSpace={9}
      assigningEditor={roundup.assigningEditor}
      hideAuthorByline={!roundup.showAuthorByline}
      hideEditorialStandards={!roundup.showEditorialDisclaimer}
    />
  )
  const footer = (
    <Footer attributionTopic={roundup.attributionTopic} hideBanner />
  )

  return (
    <ContentImpression contentId={roundup.contentId}>
      <LocationVariantProvider location={location} assignments={assignments}>
        <ApplyUrlProvider
          location={location}
          attributionTopic={roundup.attributionTopic}
        >
          <Page
            title={roundup.title}
            link={getCanonicalLink(roundup)}
            featuredImage={roundup.featuredMedia}
            seo={roundup.seo}
            jsonld={roundup.jsonld}
            header={header}
            headerContent={headerContent}
            footer={footer}
          >
            <main data-template="roundup">
              <div className={styles.defaultMargin}>
                <ExpandableContent>
                  <StructuredContentRenderer>
                    {roundup.aboveSections}
                  </StructuredContentRenderer>
                </ExpandableContent>
              </div>

              <ContentCTA uri={roundup.link} />

              <ProductTable
                validSections={validSections}
                productDescription={roundup.seo.keyword}
              />

              <div className={styles.defaultMargin}>
                <StructuredContentRenderer>
                  {roundup.belowSections}
                </StructuredContentRenderer>
              </div>

              <Box className={styles.defaultMargin}>
                <Type inline italic>
                  Last updated on <DateFormat timestamp={roundup.displayDate} />
                </Type>
              </Box>
              {!hideRecapSection && (
                <Box
                  className={classNames(
                    styles.defaultMargin,
                    styles.recapSection
                  )}
                >
                  <RecapSection
                    title={roundup.title}
                    sections={validSections}
                  />
                </Box>
              )}

              {roundup.methodology && (
                <Box className={styles.methodology}>
                  <Methodology methodology={roundup.methodology} />
                </Box>
              )}

              {roundup.faqs && (
                <Box className={styles.faqBox}>
                  <FaqSection faqs={roundup.faqs} />
                </Box>
              )}
            </main>

            <Box className={styles.defaultMargin}>
              <AuthorsSection authorBoxes={roundup.authorBox} />
            </Box>
          </Page>
        </ApplyUrlProvider>
      </LocationVariantProvider>
    </ContentImpression>
  )
}

RoundUp.propTypes = {
  data: PropTypes.shape({
    smbRoundup: PropTypes.shape({
      contentId: PropTypes.number.isRequired,
      customPath: PropTypes.string,
      breadcrumb: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        page: PropTypes.shape({
          title: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      title: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      jsonld: PropTypes.object,
      displayDate: PropTypes.string,
      attributionTopic: PropTypes.string,
      assigningEditor: PropTypes.object,
      authors: PropTypes.array,
      primaryCategory: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
      showAuthorByline: PropTypes.bool,
      showEditorialDisclaimer: PropTypes.bool,
      synopsis: PropTypes.any,
      aboveSections: PropTypes.any,
      roundupSections: PropTypes.array.isRequired,
      belowSections: PropTypes.any,
      faqs: PropTypes.array,
      authorBox: PropTypes.array,
      seo: PropTypes.shape({ keyword: PropTypes.string }).isRequired,
      featuredMedia: PropTypes.shape({}).isRequired,
      methodology: PropTypes.shape({}),
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default RoundUp

export const query = graphql`
  query ($slug: String!) {
    smbRoundup(slug: { eq: $slug }) {
      contentId
      customPath
      title
      link
      jsonld
      displayDate
      attributionTopic
      assigningEditor {
        id
        name
        link
      }
      authors {
        id
        name
        link
        url: link
      }
      primaryCategory {
        name
      }
      breadcrumb {
        slug
        page {
          title
        }
      }
      showAuthorByline
      showEditorialDisclaimer
      synopsis
      aboveSections
      roundupSections {
        categoryName
        categoryDescription
        marketplaceEntity {
          id
          status
          ... on SmallMediumBusinessLoansOfferV2 {
            prosBullets
            consBullets
            image {
              alt
              sourceMedium
            }
            displayName
            maxLoanAmount
            tagline
            qualificationsBullets
            reviewLink
            cta {
              link
              text
              subtext
            }
            institution {
              id
              name
            }
            product {
              id
            }
            productType
            placementDrivers {
              id
              label
              value
              tooltip
            }
            productLabel
          }
        }
      }
      belowSections
      faqs {
        question
        answer
        answerSchema
      }
      authorBox: authors {
        id
        name
        link
        contactEmail
        twitter
        facebook
        linkedin
        marketplaceBio
        description
        avatarUrls {
          size
          url
        }
      }
      featuredMedia {
        croppedImages {
          sourceUrl
          width
          height
        }
      }
      methodology
      seo {
        title
        description
        keyword
        facebook {
          title
          description
        }
        twitter {
          title
          description
        }
      }
    }
  }
`
